/* Customize your main colors in :root variables */
:root {
  --main-background-color: #706EAC;
  --card-background-color: #DFA4C7;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#12141D;
  --title-text-color:#F4B83D;
}

body{
  background-color:var(--main-background-color);
  font-family: 'Arial',sans-serif;
  font-size: 1.2em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

.wallet-adapter-button {
  font-family: 'Arial' !important;
}

main {
  margin-top: 35px;
}

li a {
  line-height: 40px;
  margin-left: 20px;
}

h3 a {
  color: white;
  font-weight: bold;
}
